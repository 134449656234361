body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  background-color: black;
}

#player {
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }

  .vjs-control-text {
    visibility: hidden;
  }

  .vjs-loading-spinner:before, .vjs-loading-spinner:after {
    top: 0;
    left: 0;
  }

  &.canplay {
    .play {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  &.playing {
    .play {
      opacity: 0;
      visibility: hidden;
      transform: scale(0.9);
    }
  }

  &.loading {
    .loader {
      opacity: 1;
      visibility: visible;
    }
  }
}

.play {
  position: absolute;
  width: 80px;
  height: 80px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  background: transparent;
  border: 0;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transition: all 0.2s ease-out;

  &::before {
    transition: all 0.2s ease-out;
    background-color: rgba(black, 0.8);
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0 0 10px 0 rgba(black, 0.3);
    content: ' ';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &::after {
    transform: all 0.2s ease-out;
    border-color: transparent transparent transparent white;
    border-style: solid;
    border-width: 9px 0 9px 14px;
    content: ' ';
    position: relative;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    outline: none;

    &::before {
      background-color: white;
      opacity: 1;
      transform: scale(1.1);
      visibility: visible;
    }

    &::after {
      border-color: transparent transparent transparent black;
    }
  }
}

.loader {
  position: absolute;
  width: 80px;
  height: 80px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  z-index: 20;
}
